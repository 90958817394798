/*
 * @Author: genwai
 * @Date: 2020-05-19 14:54:36
 * @LastEditTime: 2021-11-29 14:20:35
 * @LastEditors: 林娴
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\dialog\addDialogClass.js
 */
/* eslint-disable */

class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.name = ''
      this.productCode = ''
    }
  }
}
export default searchFrom
