/*
 * @Author: 陈剑伟
 * @Date: 2020-05-19 14:10:56
 * @LastEditTime: 2021-11-29 15:18:57
 * @LastEditors: 林娴
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\course.js
 */
import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.eventTime = ''
      this.storeId = null
    } else if (type === 'rules') {
      this.eventTime = [
        {
          required: true,
          message: '时间不能为空',
          trigger: ['blur', 'change'],
        },
      ]
      this.storeId = [
        {
          required: true,
          message: '仓库不能为空',
          trigger: ['blur', 'change'],
        },
      ]
    }
  }
}
export default searchFrom
